<template>
  <el-form ref="form" :model="config.form" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <topOperatingButton :isClearDetailsBtn='!disabled' :isZeroClearingBtn='!disabled'
        :isImportOrderBtn="(config.buttons&&config.buttons.isImportOrderBtn)&&showImportBill" :disabled="disabled"
        :showAudit="showAudit" :isAddBtn="showAdd" @getClearDetails='getClearDetails' @getZeroClearing='getZeroClearing'
        @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd" @getQuit="getQuit"
        @getImportOrder="handleEvent('importOrder')" id="topOperatingButton" />
      <!-- 基本信息盒子 -->
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="基本信息" :billStatus="config.form.billStatus" id="basicInformation">
        <template slot="cardContent">
          <!-- 商品信息 -->
          <div class="x-w marT10">
            <!-- 第一行 -->
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" disabled v-model="config.form.billNo" placeholder="单据编号"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" v-model="config.form.billDate" type="date" placeholder="单据日期"
                :disabled="disabled" value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input class="inputWidth" :disabled="disabled" v-model="config.form.handBillNo" placeholder="手工单据号"
                size="mini" />
            </el-form-item>
            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote v-model="config.form.produceDeptId" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled,
                          showItem: [
                          {
                            produceDeptId: config.form.produceDeptId,
                            produceDeptName: config.form.produceDeptName,
                          },
                        ]
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="生产工序" prop="processId" v-if="config.topQuery&&config.topQuery.isProcess">
              <SelectRemote v-model="config.form.processId" :option="
                  $select({
                    key: 'listProcess',
                    option: {
                      option: {
                        disabled: disabled,
                          showItem: [
                          {
                            processId: config.form.processId,
                            processName: config.form.processName,
                          },
                        ]
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input class="inputRemark" :disabled="disabled" v-model="config.form.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="rightCardTitle" v-if="config.rightCardTitle">
          <div class="marR10 x-f">
            <el-button v-if="config.rightCardTitle.isStoreBtn"
              :disabled="disabled||!config.form.produceDeptId||ids.length==0" type="primary" size="mini"
              @click="getDistribution('isStoreBtn')" class="marL10">批量修改仓库</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <vxe-table @checkbox-change="handleSelectionChange" @checkbox-all="handleSelectionChange" border show-footer
              show-overflow show-header-overflow ref="multipleTable" :data="config.form.details" :loading="loadingTable"
              max-height="580px" height="580px" :checkbox-config="{ trigger: 'row',checkMethod:()=>!disabled }"
              :row-config="{ isHover: true, height: 50 }" :sort-config="{ trigger: 'cell' }"
              :scroll-y="{ enabled: true }" :footer-method="footerMethod" size="small">
              <vxe-column fixed="left" type="checkbox" width="60" align="center" v-if="config.mutiSelect" />
              <vxe-column align="center" :title="tableCellLabel" width="80" fixed="left">
                <template v-slot="scope">
                  <div @mouseenter="cellMouseEnter(scope.row)" @mouseleave="cellMouseLeave(scope.row)">
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.rowIndex)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.rowIndex)"
                      class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.rowIndex + 1 }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <template v-for="(item, i) in config.columns">
                <!-- 动态表格 -->
                <template v-if="
                        typeof item.getColumns === 'function' &&
                        config.form.details &&
                        config.form.details.length
                      ">
                  <TableColumns v-for="(item, index) in item.getColumns(config.list)"
                    :key="`${item.prop}.${i}.${index}`" :column="item" @handleEvent="handleEvent"
                    :disabled='disabled' />
                </template>
                <slot v-else-if="item.type === 'slot'" :name="item.prop" :slot="item.prop" />
                <TableColumns v-else :key="`${item.prop}.${i}`" :column="item" @handleEvent="handleEvent"
                  :disabled='disabled'>
                  <slot v-if="item.type === 'slots'" :name="item.prop" :slot="item.prop" />
                  <slot v-if="item.type === 'multiple'" :name="item.prop" :slot="item.prop" />
                  <template :slot="'slot-' + item.prop" slot-scope="scope">
                    <slot :name="item.prop" v-bind="scope" />
                  </template>
                </TableColumns>
              </template>
            </vxe-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEventDialog" />
  </el-form>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import { getBillNo } from "@/api/codeRule"; //单据号
import { fcount, isNumber } from "@/utils/index.js"; //保留几位小数
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
export default {
  name: "tablePageDetail",
  components: {
    TableColumns: () => import("@/components/tablePageDetail/tableColumns"),
    Dialog: () => import("@/components/Dialog"),
    cardTitleCom: () => import("@/views/components/cardTitleCom"),
    topOperatingButton: () => import("@/views/components/topOperatingButton"),
    SelectRemote: () => import("@/components/tablePage/select/select-remote"),
    SelectLocal: () => import("@/components/tablePage/select/select-local"),
  },
  model: { prop: "options", event: "Device" },
  props: {
    options: {
      type: Object,
      default: () => ({ billStatus: "" }),
      required: true
    },
  },
  computed: {
    config: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("Device", val);
      },
    },
  },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择仓库",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      ids: [],  //选中商品goodsId
      tableCellLabel: "序号",
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      //表格遮罩
      loadingTable: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "生产工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        processId: [
          {
            required: true,
            message: "生产工序不能为空",
            trigger: ["blur", "change"],
          },
        ]
      },
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "config.form.billStatus": {
      handler(newVal) {
        if (this.config.form.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.config.form.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.config.form.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.config.form.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.config.form.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    "config.form.details": {
      handler(val) {
        this.$nextTick(() => {
          this.$refs.multipleTable.updateFooter();
        });
      },
      deep: true,
    },
  },
  async created() {
    await this.getInitializeData();
    if (this.config[this.config.rowKey]) {
      //获取单据详情
      const { data } = await this.config.detailApi(this.config[this.config.rowKey]);
      //单据赋值
      this.config.form = data;
      //原始单据赋值
      // this.oldForm = JSON.parse(JSON.stringify(this.config));
    } else {
      await this.getOrderBillNo();
      //判断是否有以前新增时保存的数据
      // if (JSON.stringify(this.oldForm) != "{}") {
      //   this.config = this.oldForm;
      // }
      // this.oldForm = JSON.parse(JSON.stringify(this.config));
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange() {
      const selection = this.$refs.multipleTable.getCheckboxRecords();
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
    },
    getZeroClearing() {
      this.config.form.details = this.config.form.details.filter(item => item.unitQty && item.unitQty > 0);
      if (this.config.form.details.length == 0) {
        this.config.form.details = [{}]
      }
      this.$message.success("清零成功");
    },
    getClearDetails() {
      this.reset();
      this.$message.success("清除明细成功");
    },
    async handleEvent(type, row, rowIndex) {
      this.$emit("handleEvent", type, row, rowIndex);
    },
    async getDistribution(type, row, rowIndex) {
      switch (type) {
        case 'isStoreBtn':
          this.dialogOptions.title = "选择仓库";
          this.dialogOptions.formData = this.$dialog({
            key: "produceStore",
            option: {
              table: {
                ...this.$dialog({ key: "produceStore" }).table,
                radioSelect: true,
                mutiSelect: false,
              },
            },
          });
          this.dialogOptions.formData.table.pagination = {
            ...this.dialogOptions.formData.table.pagination,
            produceDeptId: this.config?.form?.produceDeptId,
          };
          this.dialogOptions.show = true;
          break;

        default:
          break;
      }
    },
    //弹窗确定事件
    async handleEventDialog(type, row) {
      switch (type) {
        case "dialogChange":
          this.chooseOutStoreIdChange(
            this.dialogOptions.formData.table.check[0].storeId,
            this.dialogOptions.formData.table.check[0].produceStoreName
          );
          break;
        default:
          break;
      }
    },
    //切换调出仓库
    chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      this.config.form.details.forEach((item) => {
        if (this.ids.includes(item.goodsId)) {
          item.outStoreId = chooseOutStoreId;
          item.outStoreName = produceStoreName;
        }
      });
    },
    footerMethod({ columns, data }) {
      // 返回一个二维数组的表尾合计 updateFooter
      let ol = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (
            this?.config?.summary?.includes(column.property) || column.property?.indexOf?.(".") >= 0
          ) {
            return data
              .map((x) => x[column.property])
              .reduce(
                (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
                0
              );
          }
          return "";
        }),
      ];

      return ol;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.config.form.billStatus == "0" ||
        this.config.form.billStatus == "" ||
        !this.config.form.billStatus
      ) {
        this.$set(row, "hoverRow", true);
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      // 移除hover的事件
      if (
        this.config.form.billStatus == "0" ||
        this.config.form.billStatus == "" ||
        !this.config.form.billStatus
      ) {
        this.$set(row, "hoverRow", false);
        this.tableCellLabel = "序号";
      }
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({
        path: this.config.getQuitPath,
      });
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },
    //新增按钮
    async handleAdd() {
      await this.reset();
    },
    //引入源单
    getImportOrder() {
      // if (!this.config.form?.produceDeptId)
      //   return this.$message.error("生产工厂不能为空");
      // this.$router.push({
      //   name: "planImport",
      //   query: {
      //     produceGroupId: this.config?.form?.produceGroupId,
      //     produceDeptId: this.config?.form?.produceDeptId,
      //     produceOrderId: this.config?.form?.produceOrderId,
      //     type: "planImport",
      //   },
      // });
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.config.form.details.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.config.form.details.length <= 1) {
          this.config.form.details = [{
            goodsNo: "",
            hoverRow: false,
          }];
        } else {
          this.config.form.details.splice(index, 1);
        }
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.config.form.billStatus != 0) {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          const obj = [{
            billId: this.config.form.billId,
            billStatus: 2,
          }];
          //发送审核api
          const { data } = await this.config.updeteStatusApi(obj);
          //单据赋值
          this.config.form = data;
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.config.form.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = [{
            billId: this.config.form.billId,
            billStatus: 0,
          }];
          //发送反审核api
          const { data } = await this.config.updeteStatusApi(obj);
          //单据赋值
          this.config.form = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },
    //初始化页面数据
    async getInitializeData(isBool = true) {
      if (!this.loading) this.loading = true;
      //获取单据日期
      const time = getNewDate();
      this.config.form.billDate = time;
      if (isBool) {
        //默认生产工厂
        const res2 = await produceDeptList({ produceDeptNo: "0000" });
        if (res2.rows[0]?.produceDeptId) {
          //默认生产工厂
          this.$set(
            this.config.form,
            "produceDeptId",
            res2.rows[0]?.produceDeptId
          );
          //默认生产工厂名称
          this.$set(
            this.config.form,
            "produceDeptName",
            res2.rows[0]?.produceDeptName
          );
        }
      }

      this.loading = false;
    },
    //单据编号
    async getOrderBillNo() {
      let billNo = await getBillNo(this.config.billType);
      this.$set(this.config.form, "billNo", billNo);
    },
    // 表单重置
    async reset() {
      try {
        this.loading = true;
        //新增按钮
        this.showAdd = false;
        //保存禁用
        this.disabled = false;
        this.config.form = {
          billDate: "", //单据日期
          billStatus: "", //单据审核状态
          //单据表格-订单明细
          details: [{}],
        };
        this.resetForm("form");
        await this.getOrderBillNo();
        await this.getInitializeData();
      } finally {
        this.loading = false;
      }
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.config.form.billId) {
            const { data } = await this.config.updeteApi(this.config.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.config.form = data;
              this.$modal.msgSuccess(audit ? "保存成功" : "修改单据成功");
            } else {
              await this.reset();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await this.config.addApi(this.config.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.config.form = data;
              this.$modal.msgSuccess(audit ? "保存成功" : "新增单据成功");
            } else {
              await this.reset();
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
