<template>
  <component
    :is="column.children ? 'vxe-colgroup' : 'vxe-column'"
    :title="column.label"
    :width="column.width"
    :min-width="column.minWidth"
    :field="column.prop"
    :fixed="column.fixed || undefined"
    :align="column.align || 'center'"
    :show-overflow-tooltip="!['html', 'slot', 'slots', 'tag'].includes(column.type)"
  >
    <template v-if="column.children">
      <vxe-column
        v-for="(item, i) in typeof column.children === 'function'
          ? column.children()
          : column.children"
        :key="`${column.prop}.${i}`"
        :title="item.label"
        :field="item.prop"
        :width="item.width"
        :min-width="item.minWidth"
        :align="item.align || 'center'"
      >
        <template #default="{ row }">
          {{
            item.getValue
              ? item.getValue(row, item.prop)
              : item.formatter
              ? item.formatter(row[item.prop], row, columnObj)
              : row[item.prop]
          }}
        </template>
      </vxe-column>
    </template>
    <slot v-if="column.type === 'slot'" />
    <template #default="{ row }" v-if="!column.children">
      <!-- 链接内容 -->
      <el-link
        :disabled="
          typeof column.disabled === 'function' ? column.disabled(row) : column.disabled
        "
        v-if="column.link || column.type === 'link'"
        type="primary"
        class="sys-link-a"
        @click.stop="onClick(column.click, row)"
        >{{
          column.formatter
            ? column.formatter(row[column.prop], row, columnObj)
            : row[column.prop]
        }}</el-link
      >
      <template v-else-if="column.type === 'switch'">
        <el-switch
          :disabled="
            typeof column.disabled === 'function' ? column.disabled(row) : column.disabled
          "
          v-model="row[column.prop]"
          :active-value="column.active"
          :inactive-value="column.inactive"
          @change="onClick(column.click, row)"
        />
      </template>
      <!-- 图标内容 -->
      <div v-else-if="column.type === 'icons'">
        <i
          :class="
            column.formatter
              ? column.formatter(row[column.prop], row, columnObj).icon
              : column.elIcon
          "
          :style="
            column.formatter
              ? column.formatter(row[column.prop], row, columnObj).style
              : column.elStyle
          "
        />
      </div>
      <span v-else>{{
        column.formatter ? column.formatter(row[column.prop], row) : row[column.prop]
      }}</span>
    </template>
    <el-dialog :visible.sync="dialogImageVisible" :append-to-body="true">
      <!-- 不为数组时则展示一张 -->
      <img class="image" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </component>
</template>
<script>
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { limitInputlength } from "@/utils";

// 默认图片
// import defaultPic from "@/assets/fixedImages/logo/sunyun-logo.png";

export default {
  name: "DetailPageTableColumns",
  components: { SelectRemote, SelectLocal },
  props: {
    column: Object, // 详情见 element table option
  },
  data() {
    return {
      // defaultPic: defaultPic, //默认商户logo
      dialogImageVisible: false,
      dialogImageUrl: "",
    };
  },
  computed: {
    dicts() {
      let dicts = null;
      for (let index = 0; index < 10; index++) {
        dicts = dicts?.$parent || this;
        if (dicts?.$children?.find?.((x) => x.isTablePageCom) && dicts?.dict?.type) {
          dicts = dicts?.dict?.type;
          break;
        } else if (index === 9) {
          dicts = null;
        }
      }
      return dicts || {};
    },
  },
  methods: {
    //保留小数位
    limitInputlength,
    /** 图片放大处理 */
    showBigImage(url) {
      this.dialogImageUrl = url;
      this.dialogImageVisible = true;
    },

    // 层级往下，寻找字典数组
    getDictsList(name) {
      return (
        this.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[
          name
        ] ||
        {}
      );
    },

    // 查询字典值
    getDictsLabel({ name, prop }, row) {
      return this.selectDictLabel(this.getDictsList(name), row[prop]);
    },

    getDictLabel({ dict, prop, name } = {}, row) {
      const item =
        this.dicts?.[dict]?.find?.((x) => String(x.value) === String(row[prop])) || {};
      return item;
    },
    //更改事件
    selectChange({ val, obj }, change, index, prop) {
      obj.index = index;
      obj.prop = prop;
      this.$emit("handleEvent", change, obj);
    },
    // 点击事件
    onClick(click, row = null, index, prop) {
      if (row.disabled) return;
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", click, row);
    },
    //输入事件
    onInput(input, row = null, index, prop) {
      row[prop] = this.limitInputlength(row[prop], row, prop, true); //限制小数位
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", input, row);
      this.$emit("handleEvent", "updateData");
    },
  },
};
</script>
<style lang="scss" scoped>
.sys-link-a {
  &:hover {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
}

p {
  margin: 0 !important;
}

.set-popper {
  width: 331px;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
}
.set-content {
  cursor: default;
  height: 60px;
  overflow-y: auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}

.image {
  width: 30vh;
}
</style>
