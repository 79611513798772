<template>
  <vxe-table
    border
    show-overflow
    ref="mutipleTable"
    :key="tableKey"
    :loading="loading"
    :data="list"
    :height="height"
    :scroll-y="{ enabled: true }"
    :edit-config="{ trigger: 'click', mode: 'row' }"
    :mouse-config="{ selected: true }"
    keep-source
    :keyboard-config="{
      isArrow: true,
      isEsc: true,
      isDel: true,
      isEnter: true,
      isTab: true,
      isEdit: true,
    }"
    @checkbox-all="checkboxAll"
    @checkbox-change="checkboxChange"
    @cell-click="cellClick"
    :checkbox-config="{ checkMethod }"
    :radio-config="{ checkMethod }"
    :show-footer="Boolean(summary)"
    :footer-method="footerMethod"
  >
    <!--region 选择框 -->
    <vxe-column
      v-if="mutiSelect"
      type="checkbox"
      align="center"
      width="55"
      fixed="left"
    />
    <vxe-column
      v-if="radioSelect && !mutiSelect"
      width="55"
      fixed
      title="选择"
      align="center"
      class-name="radioSelect"
      header-align="center"
      type="radio"
    >
    </vxe-column>

    <!--endregion-->
    <!--region 数据列-->
    <slot name="table-column" />
  </vxe-table>
</template>
<script>
import { count, isObject, isNumber } from "@/utils";
/** 组件使用说明
 * @param {Array} list 列表数据
 * @param {Object} operates 操作按钮，不传值默认使用插槽，外显用组件写
 * @param {Boolean} operateWidth 操作列的宽度
 * @param {Boolean} loading 是否添加表格loading加载动画
 * @param {Boolean} mutiSelect 是否显示多选框
 * @param {Boolean} reserveSelection 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
 * @param {Function} headerCellStyle 合并列及行的计算
 */
export default {
  // 组件
  name: "ComponentTable",
  props: {
    checkMethod: {
      type: Function,
      default: () => true,
    },
    // 数据列表
    list: {
      type: Array,
      default: () => [],
    },
    // 操作按钮组 === label: 文本，type :类型（primary / success / warning / danger / info / text），show：是否显示，icon：按钮图标，plain：是否朴素按钮，disabled：是否禁用，method：回调方法
    operates: {
      type: [Object, Boolean],
      default: false, // 是否启用操作按钮 默认启用 传了false 不启用操作
    },
    // 操作列的宽度
    operateWidth: {
      type: String,
      default: "",
    },
    // 是否添加表格loading加载动画
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否显示多选框
    mutiSelect: {
      type: Boolean,
      default: false,
    },
    // 是否显示单选框
    radioSelect: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: [Boolean, Array],
      default: false,
    },
    summaryDateils: {
      type: Object,
      default: null,
    },
    // 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    headerCellStyle: {
      type: Function,
      default: () => {},
    },
    // 是否需要父子联动
    linkage: {
      type: Boolean,
      default: false,
    },
    height: {},
    loading: {
      type: Boolean,
    },
    tableKey: {
      type: Number,
    },
  },
  // 数据
  data() {
    return {};
  },
  watch: {
    summary: {
      handler(value) {
        const $table = this.$refs.mutipleTable;
        $table.updateFooter();
      },
      deep: true,
    },
    summaryDateils: {
      handler(value) {
        const $table = this.$refs.mutipleTable;
        $table.updateFooter();
      },
      deep: true,
    },
  },
  computed: {
    // 是否 显示按钮操作组
    operatesShow() {
      return typeof this.operates === "object" && this.operates?.list?.length > 0;
    },
  },
  methods: {
    click(btn, scope) {
      // space
      if (btn.method) {
        btn.method(scope.row, scope);
      } else {
        this.$emit("handleEvent", btn.click, row, scope);
      }
    },
    cellClick({ row, rowIndex }) {
      console.log("点击行", rowIndex);
      this.$emit("rowClick", row);
    },
    checkboxAll() {
      const selection = this.$refs.mutipleTable.getCheckboxRecords();
      this.$emit("select-all", selection);

      if (!this.linkage) return;

      const find = this.list.find((item) => {
        const ids = selection.map((item) => item.productId);

        return ids.includes(item.productId);
      });

      this.list.forEach((item) => {
        if (item.children) {
          this.selectChildren(item.children, !!find);
        }
      });
    },
    // 当选择项发生变化时会触发该事件
    checkboxChange() {
      const selection = this.$refs.mutipleTable.getCheckboxRecords();
      this.$emit("select", selection);

      if (!this.linkage) return;

      const find = selection.find((item) => item.productId === row.productId);
      row.children && this.selectChildren(row.children, !!find);
    },
    // 子级选中
    selectChildren(row, type) {
      row.map((i) => {
        this.toggleSection(i, type);
        if (i.children) {
          this.selectChildren(i.children, type);
        }
      });
    },
    footerMethod({ columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return "合计";
          }
          let summaryItem = this.summary.find((item) => item == column.field);
          if (summaryItem) {
            // if (this.summaryDateils) {
            //   const arr = column.field.split(".");
            //   // console.log("进来了嘛111", arr);
            //   // const arr = [];
            //   if (arr?.length === 4) {
            //     // const [key, id, idKey, prop] = arr;
            //     // const keyArr = key.split("_");
            //     // let detailData = null;
            //     // keyArr.forEach((x) => {
            //     //   if (detailData) {
            //     //     detailData = detailData[x];
            //     //   } else {
            //     //     detailData = this.summaryDateils[x];
            //     //   }
            //     // });
            //     // console.log("进来了嘛111");
            //     // return (
            //     //   detailData?.find?.((x) => Number(x[idKey]) === Number(id))?.[prop] ||
            //     //   "0.00"
            //     // );
            //   } else {
            //     // console.log("进来了嘛");
            //     return this.summaryDateils[column.field] || "0.00";
            //   }
            // } else {
            //   return this.sumNum(data, summaryItem);
            // }
            return this.summaryDateils
              ? this.summaryDateils[column.field] || "0.00"
              : this.sumNum(data, summaryItem);
          }
          return null;
        }),
      ];
      return footerData;
    },
    // 进行合计
    sumNum(costForm, type) {
      let total = 0;
      for (let i = 0; i < costForm.length; i++) {
        total = count([total, costForm[i][type]], "+");
      }
      if (type == "unitQty") {
      }
      return this.moneyFilter(total);
    },
    //去除.
    moneyFilter(value) {
      if (!value) return "0.00";
      value = value - 0;
      return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    getSummaries(param) {
      this.$nextTick(() => {
        try {
          // this.$refs?.mutipleTable?.doLayout();
        } catch (error) {}
      });
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const sumItem = this.summary.find((x) =>
          isObject(x) ? x.key === column.property : x === column.property
        );
        if (sumItem) {
          if (this.summaryDateils) {
            sums[index] = this.summaryDateils[column.property] || "";
          } else {
            const arr = column.property.split(".");
            let values = data.map((x) => Number(x[column.property] || 0));
            if (arr?.length === 4) {
              const [p, i, key, s] = arr;
              values = data.map(
                (x) => x[p]?.find?.((x) => String(x[key]) === i)?.[s] || 0
              );
            }
            sums[index] = `${values.reduce(
              (p, c) => count([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ""}`;
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.operate-group {
  display: flex;
  justify-content: space-around;
}

::v-deep .vxe-body--column {
  height: 38px !important;
}
::v-deep .vxe-header--column {
  padding: 8px 0 !important;
}
</style>
