var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.column.children ? "vxe-colgroup" : "vxe-column",
    {
      tag: "component",
      attrs: {
        title: _vm.column.label,
        width: _vm.column.width,
        "min-width": _vm.column.minWidth,
        field: _vm.column.prop,
        fixed: _vm.column.fixed || undefined,
        align: _vm.column.align || "center",
        "show-overflow-tooltip": !["html", "slot", "slots", "tag"].includes(
          _vm.column.type
        ),
      },
      scopedSlots: _vm._u(
        [
          !_vm.column.children
            ? {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.column.link || _vm.column.type === "link"
                      ? _c(
                          "el-link",
                          {
                            staticClass: "sys-link-a",
                            attrs: {
                              disabled:
                                typeof _vm.column.disabled === "function"
                                  ? _vm.column.disabled(row)
                                  : _vm.column.disabled,
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClick(_vm.column.click, row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.column.formatter
                                  ? _vm.column.formatter(
                                      row[_vm.column.prop],
                                      row,
                                      _vm.columnObj
                                    )
                                  : row[_vm.column.prop]
                              )
                            ),
                          ]
                        )
                      : _vm.column.type === "switch"
                      ? [
                          _c("el-switch", {
                            attrs: {
                              disabled:
                                typeof _vm.column.disabled === "function"
                                  ? _vm.column.disabled(row)
                                  : _vm.column.disabled,
                              "active-value": _vm.column.active,
                              "inactive-value": _vm.column.inactive,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onClick(_vm.column.click, row)
                              },
                            },
                            model: {
                              value: row[_vm.column.prop],
                              callback: function ($$v) {
                                _vm.$set(row, _vm.column.prop, $$v)
                              },
                              expression: "row[column.prop]",
                            },
                          }),
                        ]
                      : _vm.column.type === "icons"
                      ? _c("div", [
                          _c("i", {
                            class: _vm.column.formatter
                              ? _vm.column.formatter(
                                  row[_vm.column.prop],
                                  row,
                                  _vm.columnObj
                                ).icon
                              : _vm.column.elIcon,
                            style: _vm.column.formatter
                              ? _vm.column.formatter(
                                  row[_vm.column.prop],
                                  row,
                                  _vm.columnObj
                                ).style
                              : _vm.column.elStyle,
                          }),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.column.formatter
                                ? _vm.column.formatter(
                                    row[_vm.column.prop],
                                    row
                                  )
                                : row[_vm.column.prop]
                            )
                          ),
                        ]),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.column.children
        ? _vm._l(
            typeof _vm.column.children === "function"
              ? _vm.column.children()
              : _vm.column.children,
            function (item, i) {
              return _c("vxe-column", {
                key: _vm.column.prop + "." + i,
                attrs: {
                  title: item.label,
                  field: item.prop,
                  width: item.width,
                  "min-width": item.minWidth,
                  align: item.align || "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.getValue
                                  ? item.getValue(row, item.prop)
                                  : item.formatter
                                  ? item.formatter(
                                      row[item.prop],
                                      row,
                                      _vm.columnObj
                                    )
                                  : row[item.prop]
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }
          )
        : _vm._e(),
      _vm.column.type === "slot" ? _vm._t("default") : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogImageVisible, "append-to-body": true },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImageVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }