var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-table",
    {
      key: _vm.tableKey,
      ref: "mutipleTable",
      attrs: {
        border: "",
        "show-overflow": "",
        loading: _vm.loading,
        data: _vm.list,
        height: _vm.height,
        "scroll-y": { enabled: true },
        "edit-config": { trigger: "click", mode: "row" },
        "mouse-config": { selected: true },
        "keep-source": "",
        "keyboard-config": {
          isArrow: true,
          isEsc: true,
          isDel: true,
          isEnter: true,
          isTab: true,
          isEdit: true,
        },
        "checkbox-config": { checkMethod: _vm.checkMethod },
        "radio-config": { checkMethod: _vm.checkMethod },
        "show-footer": Boolean(_vm.summary),
        "footer-method": _vm.footerMethod,
      },
      on: {
        "checkbox-all": _vm.checkboxAll,
        "checkbox-change": _vm.checkboxChange,
        "cell-click": _vm.cellClick,
      },
    },
    [
      _vm.mutiSelect
        ? _c("vxe-column", {
            attrs: {
              type: "checkbox",
              align: "center",
              width: "55",
              fixed: "left",
            },
          })
        : _vm._e(),
      _vm.radioSelect && !_vm.mutiSelect
        ? _c("vxe-column", {
            attrs: {
              width: "55",
              fixed: "",
              title: "选择",
              align: "center",
              "class-name": "radioSelect",
              "header-align": "center",
              type: "radio",
            },
          })
        : _vm._e(),
      _vm._t("table-column"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }