export default {
  '/produce/bill/storeDistribute': {
    columns: [
      {
        prop: "goodsNo",
        label: "商品编码",
        minWidth: 50
      },
      {
        prop: "goodsName",
        label: "商品名称",
        minWidth: 100
      },
      {
        prop: "barcode",
        label: "商品条码",
        width: 50
      },
      {
        prop: "goodsSpec",
        label: "规格",
        width: 40
      },
      {
        prop: "produceGroupName",
        label: "生产组",
        width: 60
      },
      {
        prop: "outStoreName",
        label: "调出仓库",
        width: 60
      },
      {
        prop: "unitName",
        label: "单位",
        width: 40
      },
      {
        prop: "myOther",
        label: "全部分货仓库",
        width: 170,
        getColumns: (list) => {
          let colums = []
          if (list?.[0]?.storeItem?.length) {
            colums = list[0].storeItem.map((x, i) => ({
              label: x.orderStoreName,
              prop: x.orderStoreId,
              minWidth: 40,
              children: [{ label: "订货数", name: "订货数", prop: "orderQty", isDefaultCheck: 1 }, { label: "已分货数", name: "已分货数", prop: "alreadyDistributeQty" }, { label: "分货数", name: "分货数", prop: "distributeQty" },].map(y => ({
                ...y,
                prop: `storeItem.${x.distributeObjId}.distributeObjId.${y.prop}`,
                minWidth: 40,
                getValue: (row, props) => {
                  const [key, distributeObjId, idKey, prop] = props.split('.')
                  return row[key]?.find?.(x => (String(x[idKey]) === distributeObjId))?.[prop] || ''
                }
              }))
            }))
          }
          return colums
        }
      },
      {
        prop: "orderTotalQty",
        label: "订货总数",
        width: 40
      },
      {
        prop: "sourceQty",
        label: "完工总数",
        width: 40
      },
      {
        prop: "storeQty",
        label: "库存总数",
        width: 40
      },
      {
        prop: "alreadyDistributeTotalQty",
        label: "已分货总数",
        width: 40
      },
      {
        prop: "distributeTotalQty",
        label: "分货总数",
        width: 40
      },
      {
        prop: "remark",
        label: "备注",
        width: 40
      },
      {
        prop: "receivedQty",
        label: "实收数量",
        width: 40
      },
      {
        prop: "custom1",
        label: "自定义1",
        width: 40
      },
      {
        prop: "custom2",
        label: "自定义2",
        width: 40
      },
      {
        prop: "custom3",
        label: "自定义3",
        width: 40
      },
      {
        prop: "custom4",
        label: "自定义4",
        width: 40
      },
      {
        prop: "custom5",
        label: "自定义5",
        width: 40
      },
    ]
  },
  '/produce/bill/autoDistribute': {
    columns: [
      {
        prop: "lineName",
        label: "路线",
        minWidth: 170,
        align: "center",
      },
      {
        prop: "goodsNo",
        label: "商品编码",
        minWidth: 170,
        align: "center",
      },
      {
        prop: "goodsName",
        label: "商品名称",
        minWidth: 155,
        align: "center",
      },
      {
        prop: "total",
        label: "合计",
        minWidth: 120,
        align: "center",
      },
      {
        prop: "myOther",
        label: "全部分店",
        width: 170,
        getColumns: (list) => {
          let colums = []
          if (list?.[0]?.shopItem?.length) {
            console.log('list', list);
            colums = list[0].shopItem?.map((x, i) => ({
              label: x.shopName,
              prop: `${x?.deliverLineId}.${x.shopId}`,
              minWidth: 20,
              formatter: (v, row) => {
                // console.log('row', row);
                return row?.shopItem?.find?.(y => y.shopId == x.shopId)?.value || ''
              },
              children: []
              // children: [{ label: x.shopName, name: "门店名称", prop: "shopName", isDefaultCheck: 1 }].map(y => ({
              //   ...y,
              //   prop: `shopItem.${x.shopId}.orderStoreId.${y.prop}`,
              //   minWidth: 20,
              //   getValue: (row, props) => {
              //     console.log('row', row);
              //     console.log('props', props);
              //     const [shopItem, shopId, orderStoreId, prop] = props.split('.')
              //     console.log('shopId', shopId);
              //     return row?.shopItem?.find?.(x => x.shopId == shopId)?.value || ''
              //   }
              // }))
            }))
          }
          return colums
        }
      },
    ]
  },
  '/produce/bill/matchMaterial?tabIndex=1': {
    columns: [
      {
        prop: "goodsNo",
        label: "商品编码",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "goodsName",
        label: "商品名称",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "primaryRawMaterialName",
        label: "一级原料",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "secondaryRawMaterialName",
        label: "二级原料",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "tertiaryRawMaterialName",
        label: "三级原料",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "fourRawMaterialName",
        label: "四级原料",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "factLeadUnitQty",
        label: "领用数量",
        minWidth: 80,
        align: "center",
      },
      {
        prop: "factLeadUnitName",
        label: "领用单位",
        minWidth: 80,
        align: "center",
      },
    ]
  },
  '/produce/bill/matchMaterial?tabIndex=2': {
    columns: [
      {
        prop: "goodsNo",
        label: "原料编码",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "goodsName",
        label: "原料名称",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "goodsSpec",
        label: "规格",
        minWidth: 40
      },
      {
        prop: "unitName",
        label: "单位",
        minWidth: 40
      },
      {
        prop: "basUnitQty",
        label: "需求量",
        minWidth: 80
      },
      {
        prop: "factLeadBasUnitQty",
        label: "领用基本单位数量",
        minWidth: 100
      },
      {
        prop: "factLeadUnitQty",
        label: "领用数量",
        minWidth: 80,
        align: "center",
      },
      {
        prop: "factLeadUnitName",
        label: "领用单位",
        minWidth: 80,
        align: "center",
      },
    ]
  },
  '/produce/bill/matchMaterial?tabIndex=3': {
    columns: [
      {
        prop: "goodsNo",
        label: "半成品编码",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "goodsName",
        label: "半成品名称",
        minWidth: 100,
        align: "center",
      },
      {
        prop: "goodsSpec",
        label: "规格",
        minWidth: 40
      },
      {
        prop: "unitName",
        label: "单位",
        minWidth: 40
      },
      {
        prop: "basUnitQty",
        label: "需求量",
        minWidth: 80
      },
      {
        prop: "factLeadBasUnitQty",
        label: "领用基本单位数量",
        minWidth: 100
      },
      {
        prop: "factLeadUnitQty",
        label: "领用数量",
        minWidth: 80,
        align: "center",
      },
      {
        prop: "factLeadUnitName",
        label: "领用单位",
        minWidth: 80,
        align: "center",
      },
    ]
  }
}